@import "../ChallengeSidebar/ChallengeSidebar"


$toolbar-gap: 15px !default
$toolbar-button-gap: 10px !default
$toolbar-user-name-width: 180px !default
$toolbar-user-name-text-color: #000 !default
$toolbar-user-name-background-color: #fff !default


.toolbar
  position: absolute
  display: flex
  flex-direction: row
  justify-content: space-between
  left: $toolbar-gap
  right: $toolbar-gap
  top: $toolbar-gap
  pointer-events: none

  &.toolbar-narrow
    right: $toolbar-gap + $challenge-sidebar-width + $challenge-sidebar-gap

  > div
    pointer-events: auto
    display: flex
    flex-direction: row
    align-items: center

    > *:not(:first-child)
      pointer-events: auto
      margin-left: $toolbar-button-gap

    > .field
      margin-bottom: 0


.toolbar-user-name
  width: $toolbar-user-name-width
  text-overflow: ellipsis
  color: $toolbar-user-name-text-color !important
  background-color: $toolbar-user-name-background-color !important
