$modal-notification-loading-width: 64px !default
$modal-notification-loading-gap: 15px !default


.modal-notification-loading
  display: block
  width: $modal-notification-loading-width
  margin: $modal-notification-loading-gap auto 0


.modal-notification-nested
  display: block
  margin: $modal-notification-loading-gap 0 0
