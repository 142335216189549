.globe,
.globe-webgl,
.cesium-widget,
.cesium-widget canvas
  position: relative
  width: 100%
  height: 100%


.globe
  &.globe-picked
    cursor: pointer
