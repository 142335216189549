$about-table-horizontal-padding: 10px !default
$about-table-vertical-padding: 0 !default


.about-table
  border-collapse: collapse

  tr > td
    padding: $about-table-vertical-padding $about-table-horizontal-padding

    &:first-child
      padding-left: 0
      text-align: right

    &:last-child
      padding-right: 0
      text-align: left
