$challenge-item-label-opacity: 0.93 !default
$challenge-item-label-width: 270px !default
$challenge-item-label-gap: 15px !default


.challenge-item-label
  position: absolute
  left: $challenge-item-label-gap
  bottom: $challenge-item-label-gap
  width: $challenge-item-label-width
  opacity: $challenge-item-label-opacity
