$challenge-sidebar-opacity: 0.93 !default
$challenge-sidebar-width: 270px !default
$challenge-sidebar-gap: 15px !default
$challenge-sidebar-padding: 15px !default


.challenge-sidebar
  position: absolute
  width: $challenge-sidebar-width
  top: $challenge-sidebar-gap
  bottom: $challenge-sidebar-gap
  right: $challenge-sidebar-gap
  opacity: $challenge-sidebar-opacity
  overflow: hidden

  > .panel
    display: flex
    flex-direction: column
    height: 100%

    > .panel-heading
      padding-left: $challenge-sidebar-padding
      padding-right: $challenge-sidebar-padding

    > .panel-block
      overflow: auto
      flex: 1
      align-items: start
      padding-left: 0
      padding-right: 0

      > .menu
        width: 100%

        > .menu-list  a
          border-radius: 0
          padding-left: $challenge-sidebar-padding
          padding-right: $challenge-sidebar-padding
