@import "~bulma"

@import "~@fortawesome/fontawesome-free/scss/fontawesome"
@import "~@fortawesome/fontawesome-free/scss/solid"

html
  min-height: 600px
  overflow: hidden

body
  position: relative
  min-height: 100vh
  min-width: 1024px
  user-select: none
  background-color: #000

.panel-block
  background-color: #fff
  flex-direction: column
  align-items: stretch

#app,
.app
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow: hidden
